import "./App.css";
import "./external.css";
import "./custom.css";
import "./responsive.css";
import "./responsiveCustom.css";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { lazy, memo, Suspense, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import SEO from "./Pages/helper/SEO";
import ApiRequest from "./Pages/helper/ApiRequest";
import { ApiEndpoints } from "./Pages/helper/ApiEndpoints";
// import DemoButton from "./Pages/demoBtn/DemoButton";

import { NavRouter } from "./Pages/Routerpage/NavRouter";
// import ChatWidget from "./Pages/chatBoat/ChatWidget";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QueryPopup from "./Pages/Query_popup/QueryPopup"; 

const LandingPage = lazy(() => import("./Pages/landingPage/LandingPage"));
const LandingTwo = lazy(() => import("./Pages/landingTwo/LandingTwo"));

// const loadChatbotScripts = () => {
//   // Load the script dynamically
//   const script = document.createElement("script");
//   script.src = "//in.fw-cdn.com/31437866/703378.js";
//   script.setAttribute("chat", "true");
//   script.setAttribute("widgetId", "29ce8892-b5f9-4501-b7fa-9dfd898527c5");
//   document.body.appendChild(script);

//   // Configure the chatbot
//   window.fcWidgetMessengerConfig = { tags: ["b2b sales"] };
//   script.onload = () => {
//     setTimeout(() => {
//       if (window.fcWidget) window.fcWidget.open();
//     }, 10000); // 10 seconds delay
//   };
// };

function App() {
  const [seoData, setSeoData] = useState(null);
  const seoByUrl = ApiEndpoints.seo.seoByUrl;
  const location = useLocation();
  const landingPageUrl = location.pathname;

  // loading chatbot script
  // useEffect(() => {
  //   loadChatbotScripts();

  //   return () => {
  //     // Cleanup: Remove the script if needed
  //     const existingScript = document.querySelector(
  //       'script[src="//in.fw-cdn.com/31437866/703378.js"]'
  //     );
  //     if (existingScript) existingScript.remove();
  //   };
  // }, []);

  // fetching seo new only one for one page
  useEffect(() => {
    const dataFetch = async () => {
      try {
        const response = await ApiRequest("GET", `${seoByUrl}${location.pathname}`);
        const result = response.seo;
        setSeoData(result);
      } catch (error) {
        console.error("Error fetching SEO data:", error);
      }
    };
    dataFetch();
  }, [location.pathname]);


  // to check user visiting which page
  // useEffect(() => {
  //   const sessionCheck = sessionStorage.getItem("sourceUrlSession");
  //   if (!sessionCheck) {
  //     const referrer = document.referrer || window.location.href;
  //     sessionStorage.setItem("sourceUrlSession", referrer);
  //   }
  // }, []);
  useEffect(() => {
    const sessionCheck = sessionStorage.getItem("sourceUrlSession");
    if (!sessionCheck) {
      // Capture the URL of the first page the user lands on
      const currentUrl = window.location.href;
      sessionStorage.setItem("sourceUrlSession", currentUrl);
    }
  }, []);

  // Storing referrer or current URL in sessionStorage  
  const NormalizeTrailingSlash = memo(() => {
    const location = useLocation();
    const { pathname, search, hash } = location;

    useEffect(() => {
      if (pathname.endsWith('/') && pathname !== '/') {
        const newPath = pathname.slice(0, -1) + search + hash;

        // Update the URL only if it's different
        if (newPath !== window.location.href) {
          window.history.replaceState(null, '', newPath);
        }
      }
    }, [pathname, search, hash]);
    return null;
  });

  // if landing page than change routes
  if (landingPageUrl.includes("/white-label-taxi-dispatch-software-app")) {
    return (
      <>
        <Suspense fallback={
          <>
            {/* <div className="loader-screen">
                <img src="/assets/home/loading-logo.webp" alt="logo" className="img-responsive" />
              </div> */}
            <div className="loader-only-css">
              <div className="loader-inner-css">
                <div></div>
              </div>
            </div>
          </>
        }>
          <div className="app">
            <HelmetProvider>
              <SEO seo={seoData} />
              {/* <DemoButton/> */}
              <Routes>
                <Route path="/white-label-taxi-dispatch-software-app" element={<LandingPage />} />
              </Routes>
            </HelmetProvider>
          </div>
          <QueryPopup/>
        </Suspense>
      </>
    );
  }

  if (landingPageUrl.includes("/best-white-label-taxi-dispatch-software")) {
    return (
      <>
        <Suspense fallback={
          <>
            <div className="loader-only-css">
              <div className="loader-inner-css">
                <div></div>
              </div>
            </div>
          </>
        }>
          <div className="app">
            <HelmetProvider>
              <SEO seo={seoData} />
              {/* <DemoButton/> */}
              <Routes>
                <Route path="/best-white-label-taxi-dispatch-software" element={<LandingTwo />} />
              </Routes>
            </HelmetProvider>
          </div>
          <QueryPopup/>
        </Suspense>
      </>
    );
  }

  // else return our full app
  return (
    <>
      <div className="app">
        <HelmetProvider>
          <SEO seo={seoData} />
          {/* <DemoButton/> */}
          <NormalizeTrailingSlash />
          <NavRouter />
          <ToastContainer />
        </HelmetProvider>
        <QueryPopup/>
      </div>
    </>
  );
}

export default App;
