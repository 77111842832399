import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const ScrollToTop = lazy(() => import("./ScrollToTop"));
const ComingSoon = lazy(() => import("../comingSoon/ComingSoon"));
const Restaurant = lazy(() => import("../restaurant/Restaurant"));
const Telemedicine = lazy(() => import("../telemedicine/Telemedicine"));
const CloneAppsDynamic = lazy(() => import("../cloneApps/CloneAppsDynamic"));
const Education = lazy(() => import("../education/Education"));
const FoodAndGrocery = lazy(() => import("../foodAndGrocery/FoodAndGrocery"));
const HealthCare = lazy(() => import("../healthcare/HealthCare"));
const Industries = lazy(() => import("../industries/Industries"));
const Home = lazy(() => import("../Home/Home"));
const About = lazy(() => import("../About/About"));
const Contact = lazy(() => import("../Contact/Contact"));
const BlogListing = lazy(() => import("../Blogs/BlogListing"));
const BlogDetails = lazy(() => import("../BlogDetails/BlogDetails"));
const Layout = lazy(() => import("../Layouts/Layout"));
const Login = lazy(() => import("../login/Login"));
const AdminLayout = lazy(() => import("../Layouts/AdminLayout"));
const Dashboard = lazy(() => import("../adminPanel/dashboard/Dashboard"));
const BlogListingAdmin = lazy(() => import("../adminPanel/blogs/BlogListingAdmin"));
const AddBlogAdmin = lazy(() => import("../adminPanel/blogs/AddBlogAdmin"));
const EditBlogAdmin = lazy(() => import("../adminPanel/blogs/EditBlogAdmin"));
const AuthorListing = lazy(() => import("../adminPanel/author/AuthorListing"));
const AddAuthor = lazy(() => import("../adminPanel/author/AddAuthor"));
const EditAuthor = lazy(() => import("../adminPanel/author/EditAuthor"));
const Tags = lazy(() => import("../adminPanel/tags/Tags"));
const AddTags = lazy(() => import("../adminPanel/tags/AddTags"));
const EditTags = lazy(() => import("../adminPanel/tags/EditTags"));
const Category = lazy(() => import("../adminPanel/category/Category"));
const AddCategory = lazy(() => import("../adminPanel/category/AddCategory"));
const EditCategory = lazy(() => import("../adminPanel/category/EditCategory"));
const SeoListing = lazy(() => import("../adminPanel/seo/SeoListing"));
const SeoCreate = lazy(() => import("../adminPanel/seo/SeoCreate"));
const SeoEdit = lazy(() => import("../adminPanel/seo/SeoEdit"));
const ShuttleSoftware = lazy(() => import("../shuttle_software/ShuttleSoftware"));
const CarRental = lazy(() => import("../carRental/CarRental"));
const PageNotFound = lazy(() => import("../404page/PageNotFound"));
const ThankYou = lazy(() => import("../thankyou/ThankYou"));
const CarPooling = lazy(() => import("../carPooling/CarPooling"));
const PrivacyPolicy = lazy(() => import("../privacy/PrivacyPolicy"));
const CaseStudyListing = lazy(() => import("../caseStudy/CaseStudyListing"));
const CaseStudyDetailNew = lazy(() => import("../caseStudyDetails/CaseStudyDetailNew"));
const CaseStudyListAdmin = lazy(() => import("../adminPanel/caseStudy/CaseStudyListAdmin"));
const AddCaseStudy = lazy(() => import("../adminPanel/caseStudy/AddCaseStudy"));
const EditCaseStudy = lazy(() => import("../adminPanel/caseStudy/EditCaseStudy"));
const CaseCategoryList = lazy(() => import("../adminPanel/caseCategory/CaseCategoryList"));
const AddCaseCategory = lazy(() => import("../adminPanel/caseCategory/AddCaseCategory"));
const EditCaseCategory = lazy(() => import("../adminPanel/caseCategory/EditCaseCategory"));
const Pricing = lazy(() => import("../pricing/Pricing"));
const WhiteLabelSolutions = lazy(() => import("../WhiteLabelSolutions/WhiteLabel"));
const Features = lazy(() => import("../Features/Features"));
const BikeRental = lazy(() => import("../bikeRental/BikeRental"));
const TaxiDispatchSoftware = lazy(() => import("../taxiDispatchSoftware/taxiDispatchSoftware"));
const CustomAppDevelopment = lazy(() => import("../customAppDevelopment/CustomAppDevelopment"));
const WebAppDevelopment = lazy(() => import("../webAppDevelopment/WebAppDevelopment"));
const TaxiSoftware = lazy(() => import("../TaxiSoftware/TaxiSoftware"));
const ContactListingAdmin = lazy(() => import("../adminPanel/contact/contactListingAdmin"));
const FeaturePageListing = lazy(() => import("../adminPanel/webPages/FeaturePageListing"));
const FeaturePageCreate = lazy(() => import("../adminPanel/webPages/FeaturePageCreate"));
const FeaturePageEdit = lazy(() => import("../adminPanel/webPages/FeaturePageEdit"));
const FeatureEdit = lazy(() => import("../adminPanel/feature/FeatureEdit"));
const FeatureCreate = lazy(() => import("../adminPanel/feature/FeatureCreate"));
const FeatureListing = lazy(() => import("../adminPanel/feature/FeatureListing"));
const MediaPage = lazy(() => import("../Media_Page/MediaPage"));
const Pharmacy = lazy(() => import("../pharmacy/Pharmacy"));
const Ecommerce = lazy(() => import("../ecommerce/Ecommerce"));
const AdminMedia = lazy(() => import("../adminPanel/media/AdminMedia"));
const AddMedia = lazy(() => import("../adminPanel/media/AddMedia"));
const EditMedia = lazy(() => import("../adminPanel/media/EditMedia"));
const CloneApps = lazy(() => import("../adminPanel/cloneApps/CloneApps"));
const AddCloneApps = lazy(() => import("../adminPanel/cloneApps/AddCloneApps"));
const EditCloneApps = lazy(() => import("../adminPanel/cloneApps/EditCloneApps"));
const AddTestimonial = lazy(() => import("../adminPanel/testimonial/AddTestimonial"));
const TestimonialList = lazy(() => import("../adminPanel/testimonial/TestimonialList"));
const EditTestimonial = lazy(() => import("../adminPanel/testimonial/EditTestimonial"));
const EmailDynamic = lazy(() => import("../adminPanel/emailDynamic/EmailDynamic"));
const AddEmails = lazy(() => import("../adminPanel/emailDynamic/AddEmails"));
const EditEmails = lazy(() => import("../adminPanel/emailDynamic/EditEmails"));

export const NavRouter = () => {
  return (
    <>
      <Suspense fallback={
      <>
        {/* <div className="loader-screen">
          <img loading="lazy" src="/assets/home/loading-logo.webp" alt="logo" className="img-responsive" />
        </div> */}
        <div className="loader-only-css">
          <div className="loader-inner-css">
            <div></div>
          </div>
        </div>
      </>
      }>
        <ScrollToTop />
        <Routes>

          {/* admin layout */}
          <Route path="Login" element={<Login />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<Dashboard />} />

            <Route path="blog" element={<BlogListingAdmin />} />
            <Route path="add-blog" element={<AddBlogAdmin />} />
            <Route path="edit-blog/:blog_id" element={<EditBlogAdmin />} />

            <Route path="author" element={<AuthorListing />} />
            <Route path="add-author" element={<AddAuthor />} />
            <Route path="edit-author/:author_id" element={<EditAuthor />} />

            <Route path="tags" element={<Tags />} />
            <Route path="add-tags" element={<AddTags />} />
            <Route path="edit-tags/:tags_id" element={<EditTags />} />

            <Route path="category" element={<Category />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="edit-category/:category_id" element={<EditCategory />}/>

            <Route path="seo" element={<SeoListing />} />
            <Route path="add-seo" element={<SeoCreate />} />
            <Route path="edit-seo/:seo_id" element={<SeoEdit />} />

            <Route path="feature-pages" element={<FeaturePageListing />} />
            <Route path="add-feature-page" element={<FeaturePageCreate />} />
            <Route path="edit-feature-page/:featurepage_id" element={<FeaturePageEdit />}/>

            <Route path="features" element={<FeatureListing />} />
            <Route path="add-feature" element={<FeatureCreate />} />
            <Route path="edit-feature/:featurepage_id" element={<FeatureEdit />}/>

            <Route path="case-study" element={<CaseStudyListAdmin />} />
            <Route path="add-case-study" element={<AddCaseStudy />} />
            <Route path="edit-case-study/:casestudy_id" element={<EditCaseStudy />}/>

            <Route path="media" element={<AdminMedia />} />
            <Route path="add-media" element={<AddMedia />} />
            <Route path="edit-media/:media_id" element={<EditMedia />}/>

            <Route path="case-category" element={<CaseCategoryList />} />
            <Route path="add-case-category" element={<AddCaseCategory />} />
            <Route path="edit-case-category/:casecategory_id" element={<EditCaseCategory />} />

            <Route path="clone-app" element={<CloneApps />} />
            <Route path="add-clone-app" element={<AddCloneApps />} />
            <Route path="edit-clone-app/:cloneapp_url" element={<EditCloneApps />} />

            <Route path="testimonial" element={<TestimonialList />} />
            <Route path="add-testimonial" element={<AddTestimonial />} />
            <Route path="edit-testimonial/:testimonial_id" element={<EditTestimonial />} />

            <Route path="emails" element={<EmailDynamic />} />
            <Route path="add-emails" element={<AddEmails />} />
            <Route path="edit-emails/:email_id" element={<EditEmails />} />

            <Route path="contact" element={<ContactListingAdmin />} />
          </Route>

          {/* user layout */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About /> } />
            <Route path="contact" element={<Contact /> } />
            <Route path="blog" element={<BlogListing /> } />

            <Route path="blog/:blog_slug" element={<BlogDetails /> } />
            <Route path="features/:featurepageId" element={<Features /> } />

            <Route path="best-taxi-dispatch-software" element={<TaxiSoftware /> }/>
            <Route path="shuttle-software" element={<ShuttleSoftware /> } />
            <Route path="car-rental-software" element={<CarRental /> } />
            <Route path="thankyou" element={<ThankYou /> } />
            <Route path="404" element={<PageNotFound /> } />
            <Route path="*" element={<Navigate to={"/404"} />} />
            <Route path="white-lable-carpool-app-dispatch-software" element={<CarPooling /> }/>
            <Route path="privacy-policy" element={<PrivacyPolicy />} />

            {/* <Route path="case-study" element={<CaseStudy />} /> */}
            <Route path="case-study" element={<CaseStudyListing /> } />
            {/* <Route path="case-study-detail" element={<CaseStudyDetail />} /> */}
            <Route path="case-study/:casestudy_slug" element={<CaseStudyDetailNew /> } />

            <Route path="pricing" element={<Pricing /> } />
            <Route path="white-label-solutions" element={<WhiteLabelSolutions /> }/>

            <Route path="/car-pooling" element={<Navigate to="/white-lable-carpool-app-dispatch-software" replace />}/>
            <Route path="/taxi-software" element={<Navigate to="/best-taxi-dispatch-software" replace/>}/>
            <Route path="/car-rental" element={<Navigate to="/car-rental-software" replace />}/>
            <Route path="features" element={<Features />} />
            <Route path="bike-rental" element={<BikeRental /> } />
            <Route path="airport-transfer-software" element={<TaxiDispatchSoftware /> }/>

            <Route path="custom-app-development" element={<CustomAppDevelopment /> }/>

            <Route path="web-app-development" element={<WebAppDevelopment /> } />
            
            
            <Route path="best-logistic-app-development-company" element={<Industries /> } />
            <Route path="industries" element={<Navigate to={"/best-logistic-app-development-company"} replace/>} />


            <Route path="custom-healthcare-app-development-services" element={<HealthCare /> } />
            <Route path="healthcare" element={<Navigate to={"/custom-healthcare-app-development-services"} replace/>} />
            

            <Route path="food-grocery-delivery-app-development-company" element={<FoodAndGrocery /> } />
            <Route path="food-and-grocery-app" element={<Navigate to={"/food-grocery-delivery-app-development-company"} replace/>} />

            <Route path="media" element={<MediaPage /> } />


            <Route path="best-pharmacy-app-development-company" element={<Pharmacy /> } />
            <Route path="pharmacy-app" element={<Navigate to={"/best-pharmacy-app-development-company"} replace/>} />


            <Route path="ecommerce-mobile-app-development-company" element={<Ecommerce /> } />
            <Route path="ecommerce-app" element={<Navigate to={"/ecommerce-mobile-app-development-company"} replace/>} />

            <Route path="education-app-development-services" element={<Education /> } />
            <Route path="telemedicine-app-development-company" element={<Telemedicine /> } />
            <Route path="restaurant-app-development-company" element={<Restaurant /> } />

            <Route path="coming-soon" element={<ComingSoon /> } />
            <Route path="clone-app/:cloneapp_url" element={<CloneAppsDynamic /> } />
            <Route path="lyft-clone-app" element={<Navigate to={"/clone-app/lyft-clone-app"} replace/>} />
            <Route path="uber-clone-app" element={<Navigate to={"/clone-app/uber-clone-app"} replace/>} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};
