import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import { Provider } from "react-redux";
// import * as Sentry from "@sentry/react";


// Sentry.init({
//   dsn: "https://f481191df3c8f2731f9ed284dffafeef@o4508572512485376.ingest.us.sentry.io/4508573183836160",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["localhost", /^https:\/\/www.mobilityinfotech.com/, /^http:\/\/64.227.176.142:3005/,],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });


const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
