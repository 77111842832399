import { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closePopup } from "../../store/popupSlice";
import { ApiEndpoints } from "../helper/ApiEndpoints";
import ApiRequest from "../helper/ApiRequest"
// import { useGeolocated } from "react-geolocated";
import { callingCountries } from 'country-data';
import Flag from "react-world-flags";
import { resetForm } from '../../store/formSlice';
import ReCAPTCHA from "react-google-recaptcha";
import { setName, setEmail, setPhoneNumber, setSubject, setMessage, setSelectedCountry, setSourceUrl } from "../../store/formSlice"

export default function QueryPopup() {

  const addContactEndPoints = ApiEndpoints.contact.contactCreate;
  const [captchaToken, setCaptchaToken] = useState(""); // reCAPTCHA token state
  const [captchaError, setCaptchaError] = useState("");

  const { name, email, phoneNumber, subject, message, selectedCountry, sourceUrl } = useSelector((state) => state.form);
  const [errors, setErrors] = useState({});
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const isPopupOpen = useSelector((state) => state.popup.isPopupOpen);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsCountryDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const filteredCountries = callingCountries.all.filter(country =>
    !country.countryCallingCodes.includes("+247") && // AC
    !country.countryCallingCodes.includes("+241") && // FX
    !country.countryCallingCodes.includes("+290") && // TA
    country.alpha2 !== "AC" &&
    country.alpha2 !== "FX" &&
    country.alpha2 !== "TA"
  );

  // useEffect(() => {
  //   const sessionUrl = sessionStorage.getItem("sourceUrlSession");
  //   if (sessionUrl) {
  //     dispatch(setSourceUrl(sessionUrl));
  //   }
  // }, []);

  useEffect(() => {
    const sessionUrl = sessionStorage.getItem("sourceUrlSession");
  
    // Check if the URL is already stored in sessionStorage
    if (!sessionUrl) {
      const currentUrl = window.location.href; // Get the current URL of your website
      const domain = window.location.origin; // Your website's domain (e.g., https://www.example.com)
  
      // Store only if the current URL belongs to your website
      if (currentUrl.startsWith(domain)) {
        sessionStorage.setItem("sourceUrlSession", currentUrl);
        dispatch(setSourceUrl(currentUrl));
      }
    } else {
      dispatch(setSourceUrl(sessionUrl)); // Use the stored URL
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const phoneRegex = /^[0-9]+$/;
    const messageRegex = /\b\w+\b/g;

    if (!nameRegex.test(name)) {
      newErrors.name = "Name must contain only letters.";
    }

    if (!emailRegex.test(email)) {
      newErrors.email = "Invalid email address. Example: user@example.com";
    }

    if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must contain only numbers.";
    }

    // if ((message.match(messageRegex) || [])) {
    //   newErrors.message = "Message must contain at least 10 words.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      setCaptchaError("Please complete the CAPTCHA.");
      return;
    }

    if (validateForm()) {
      const payload = {
        name,
        email,
        message,
        subject,
        phoneNumber: `${selectedCountry.code}-${phoneNumber}`,
        source_url: sourceUrl,
        country: selectedCountry.name,

      };
      console.log(payload);
      try {
        const response = await ApiRequest("POST", addContactEndPoints, payload);
        console.log(response);
        dispatch(resetForm());
        // alert("Message sent successfully!");
        dispatch(closePopup())
        navigate("/thankyou"); // Replace with the appropriate route
      } catch (error) {
        console.error("There was an error sending the message!", error);
        // alert("There was an error sending the message!");
      }
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaToken(value);
    setCaptchaError(""); // Reset error when CAPTCHA is completed
  };
  return (
    <>
      <Modal
        className="query-popup"
        size="lg"
        show={isPopupOpen}  // Show modal based on Redux state
        onHide={() => dispatch(closePopup())}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-dialog-centered">

        <Modal.Body>
          <button type="button" className="btn-close popclose" onClick={() => dispatch(closePopup())} aria-label="Close"></button>

          <div className="row m-0">
            <div className="col-lg-6 col-md-6 no-padd">
              <div className="querypop-image">
                <img loading='lazy' src="../assets/home/query-image.webp" alt="query" className="img-responsive" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 no-padd">
              <div className="query-formbox">
                <h4 className="heading center-heading">Get In Touch</h4>
                <form onSubmit={handleSubmit}>
                  <div className="form-field half-field">
                    <label>Full Name*</label>
                    <input type="text" value={name} onChange={(e) => {
                      const regex = /^[A-Za-z\s]*$/;
                      if (regex.test(e.target.value)) {
                        dispatch(setName(e.target.value));
                      }
                    }} placeholder="Enter Name" className="form-control" required />
                  </div>

                  <div className="form-field half-field">
                    <label>Your Email*</label>
                    <input type="email" value={email}
                      onChange={(e) => dispatch(setEmail(e.target.value))} placeholder="Enter Email" className="form-control" required />
                    {errors.email && <span className="text-danger">{errors.email}</span>}

                  </div>

                  <div className="form-field">
                    <label>Phone number*</label>
                    <div className="conform-phone">
                      <div style={{ position: 'relative' }} ref={dropdownRef}>
                        <div
                          onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                          className="country-flag"

                        >
                          <Flag code={selectedCountry.alpha2} style={{ width: '20px', marginLeft: '10px' }} />
                          <span style={{ marginRight: '10px', marginLeft: '10px' }}>{selectedCountry.code}</span>
                          <span>{(selectedCountry.alpha2)}</span>
                        </div>
                        {isCountryDropdownOpen && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: 0,
                              zIndex: 1000,
                              maxHeight: '200px',
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: 'white',

                            }}
                          >
                            {filteredCountries.map((country, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  dispatch(setSelectedCountry({
                                    code: country.countryCallingCodes[0],
                                    alpha2: country.alpha2,
                                    name: country.name
                                  }));
                                  setIsCountryDropdownOpen(false);
                                }}
                                style={{
                                  padding: '5px',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: "13px",
                                  width: "100%"
                                }}
                              >
                                <span style={{ width: "20%" }}><Flag code={country.alpha2} style={{ width: '20px', marginLeft: '10px', marginRight: '10px' }} /></span>
                                <span style={{ margin: '0 10px 0 20px', width: "40%" }}>{country.countryCallingCodes[0]}</span>
                                <span style={{ width: "20%" }}>{country.alpha2}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number*"
                        value={phoneNumber}
                        required
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, "");
                          dispatch(setPhoneNumber(value));
                        }}
                      />
                    </div>
                    {/* <input type="text" placeholder="Your Phone Number" className="form-control"  required /> */}
                  </div>

                  <div className="form-field">
                    <label>Select Service*</label>
                    <select className="form-control" value={subject}
                      onChange={(e) => dispatch(setSubject(e.target.value))} required>
                      <option value="" disabled>Select Service*</option>
                      <option value="Taxi Dispatch Software">Taxi Dispatch Software</option>
                      <option value="Airport Transfer Software">Airport Transfer Software</option>
                      <option value="Car Rental Software">Car Rental Software</option>
                      <option value="Carpool & Rideshare Software">Carpool & Rideshare Software</option>
                      <option value="Shuttle Services">Shuttle Services</option>
                      <option value="Custom App Development">
                        Custom App Development
                      </option>
                      <option value="Web App Development">
                        Web App Development
                      </option>
                      <option value="Bike Rental Software">
                        Bike Rental Software
                      </option>
                      <option value="White Label Software">
                        White Label Software
                      </option>
                    </select>
                  </div>

                  <div className="form-field">
                    <label>Technology Solution*</label>
                    <textarea className="form-control" value={message}
                      required
                      onChange={(e) => dispatch(setMessage(e.target.value))}
                      placeholder="Write Your Message*">


                    </textarea>
                    <small className="trial-in-days">*21 Days Free Trial | No Credit Card Required</small>
                  </div>
                  <div className="recaptcha-container">
                    <ReCAPTCHA
                      sitekey="6LfWqGcqAAAAANfhWJbRj_f62OnRQsYVqnzj-xr5" // Your reCAPTCHA site key
                      onChange={onCaptchaChange}
                    />
                  </div>
                  {errors.message && <span className="text-danger">{errors.message}</span>}



                  <div className="query-pop-btn text-center">
                    <button class="default-btn">SUBMIT</button>
                  </div>
                </form>

                <img loading='lazy' src="../assets/home/pop-pattern-1.webp" className="pop-vector-1" alt="vector" />
                <img loading='lazy' src="../assets/home/pop-pattern-2.webp" className="pop-vector-2" alt="vector" />
                <img loading='lazy' src="../assets/home/pop-pattern-3.webp" className="pop-vector-3" alt="vector" />
              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>
    </>
  )
}
