import { Helmet } from 'react-helmet';

const SEO = ({ seo }) => {
  
  if (!seo) {
    return null;
  }

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{seo?.title || 'Default Title'}</title>
      <meta name="keywords" content={seo?.keywords || 'default, keywords'} />
      <meta name="description" content={seo?.description || 'Default description'} />
      <link rel="canonical" href={seo?.canonical_tag || window.location.href} />

       {/* Open Graph Meta Tags */}
      <meta property="og:locale" content={seo?.og_locale || 'en_US'} />
      <meta property="og:type" content={seo?.og_type || 'website'} />
      <meta property="og:title" content={seo?.og_title || 'Default Title'} />
      <meta property="og:description" content={seo?.og_description || 'Default description'} />
      <meta property="og:url" content={seo?.og_url || window.location.href} />
      <meta property="og:site_name" content={seo?.og_site_name || 'Default Site Name'} />
      {seo.og_image && <meta property="og:image" content={seo.og_image} />}
     
     
      

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={seo?.twitter_card || 'summary_large_image'} />
      <meta name="twitter:site" content={seo?.twitter_site || '@default_site'} />
      <meta name="twitter:title" content={seo?.twitter_title || 'Default Title'} />
      <meta name="twitter:description" content={seo?.twitter_description || 'Default description'} />
      {seo.twitter_image && <meta name="twitter:image" content={seo.twitter_image} />}
      {seo.twitter_creator && <meta name="twitter:creator" content={seo.twitter_creator} />}

      {/* Faq Schema */}
      {seo.faq_schema && (
        <script type="application/ld+json">
          {(seo.faq_schema)}
        </script>
      )}
      {seo.article_schema && (
        <script type="application/ld+json">
          {(seo.article_schema)}
        </script>
      )}
      {seo.review_schema && (
        <script type="application/ld+json">
          {(seo.review_schema)}
        </script>
      )}
      {seo.organisation_schema && (
        <script type="application/ld+json">
          {(seo.organisation_schema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;